<template>
	<div class="router-view-wrapper">
		<q-list bordered class="rounded-borders">
			<q-expansion-item
				v-model="expand_story"
				class="text-left"
				expand-separator
				:label="story.title"
				header-class="text-primary"
			>
				<q-list bordered separator>
					<q-item
						clickable
						v-ripple
						v-for="help_item in story.help_items"
						:key="help_item.id"
						:inset-level="0.2"
						:to="{
							name: 'Help & Support / Details',
							params: {id: help_item.id, story: help_item.help_story}
						}"
					>
						<q-item-section>{{ help_item.title }}</q-item-section>
					</q-item>
				</q-list>
			</q-expansion-item>
		</q-list>
	</div>
</template>

<script>

export default {
	name:"StoryItem",
	props: {
		story: Object,
		expanded: Boolean,
	},
	data() {
		return {
			expand_story: false
		}
	},
	mounted () {
		this.expand_story=this.expanded
	},
}
</script>

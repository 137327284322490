<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Help and support
		</h1>
		<q-input
			outlined
			v-model="search_value"
			:hint="hint"
			label="Search term"
			@input="searchItems()"
		/>
		<div style="overflow-x: auto" v-if="matched_stories_list.length">
			<ul
				v-for="story in matched_stories_list"
				:key="story.id"
				:class="$q.screen.gt.sm ? 'ul-width' : ''"
				class="q-pa-none"
			>
				<StoryItem
					:story="story"
					:expanded="true"
				/>
			</ul>
		</div>
		<div style="overflow-x: auto" v-else-if="matched_stories_list.length === 0 && search_value === ''">
			<ul
				v-for="story in all_stories"
				:key="story.id"
				:class="$q.screen.gt.sm ? 'ul-width' : ''"
				class="q-pa-none"
			>
				<StoryItem
					:story="story"
					:expanded="false"
				/>
			</ul>
		</div>
	</div>
</template>

<script>
import StoryItem from "@/components/help_and_support/StoryItem.vue";
import HelpAndSupportAPI from '@/services/api/help&support.js';

export default {
	name: "StoryList",
	meta: {
		title: 'Alert Cascade - Help and Support'
	},
	components: {
		StoryItem,
	},
	data() {
		return {
			matched_stories_list: [],
			search_value: '',
			hint: 'Search by name or relevant data contained in the items',
			all_stories: []
		};
	},
	async created() {
		const response = await HelpAndSupportAPI.getStories();
		this.all_stories = response.results;
	},
	methods:{
		async searchItems(){
			if(this.search_value.length > 1){
				const response = await HelpAndSupportAPI.searchItems(this.search_value);
				this.matched_stories_list = response.data.results;
				this.hint = !this.matched_stories_list.length ? "There are no results." : 'Search by name or relevant data contained in the items'
			}else{
				this.matched_stories_list = []
				this.hint = 'Search by name or relevant data contained in the items'
			}
		},
	}
};
</script>

<style lang="scss" scoped>
	.ul-width {
		min-width: 550px;
	}
</style>
